<template>
  <div class="lists-wrapper__lists">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info">
          <span class="grid-heading">
            <h1>{{ $t('Serial number') }}</h1>
            <span class="entries">{{ NumberFormat(entries) }} {{ $t(`entr${entries == 1 ? 'y' : 'ies'}`) }}</span>
          </span>
          <div class="grid-search">
            <v-select :class="{'vs--loading': spinner}" @input="Select" :options="identifiers" :placeholder="$t('Search')">
              <template slot="spinner">
                <div class="vs__spinner" v-show="spinner" />
              </template>
              <template v-slot:selected-option="option">
                <span>{{ option.code }}: {{ option.order }} - {{ DateFormat(option.date) }}</span>
              </template>
              <template slot="option" slot-scope="option">
                <span>{{ option.code }}: {{ option.order }} - {{ DateFormat(option.date) }}</span>
              </template>
            </v-select>
          </div>
        </div>
        <table class="list table odd-even">
          <thead>
            <tr class="list__actions">
              <th class="company">{{ $t('Company') }}</th>
              <th class="increment">{{ $t('Order number') }}</th>
              <th class="order">{{ $t('Parcel') }}</th>
              <th class="product">{{ $t('Product') }}</th>
              <th class="sku">{{ $t('SKU') }}</th>
              <th class="identifier">{{ $t('Serial number') }}</th>
              <th class="created-by">{{ $t('Created by') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="list__row" v-for="(item, index) in products" :key="index">
              <td class="company">{{ item.company_name }}</td>
              <td class="increment" v-html="Hyperlink({href: ParcelOrder(item.order_id), target: '_blank', text: item.increment_id})" />
              <td class="order" v-html="Hyperlink({href: ParcelOrder(item.order_id), target: '_blank', text: item.order_id})" />
              <td class="product">{{ item.product }}</td>
              <td class="sku">{{ item.sku }}</td>
              <td class="identifier">{{ item.identifier }}</td>
              <td class="created-by">{{ item.created_by }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { BPA }                    from '@/helpers/BPA';
  import { Tool }                   from '@/helpers/Tool';

  export default {
    name: 'ProductsSerials',
    mixins: [BPA, FormElementsBehaviour, TableElementsBehaviour],
    components: {},
    data() {
      return {
        spinner: false,
        loading: false,
        identifier: '',
        identifiers: [],
        products: [],
        entries: 0,
        list: {
          search: '',
          items: [],
          data: []
        }
      };
    },
    async created() {
      //this.loading = true;

      this.spinner = true;
      this.identifiers = (await this.GetProductUniqueIdentifiers()).map(o => ({
        code: o.identifier, 
        label: `${o.identifier}: ${o.order_item_id} - ${o.created_at}`, 
        order: o.order_item_id, 
        date: o.created_at
      })).sort((a, b) => new Date(b.date) - new Date(a.date));
      this.spinner = false;

      /*
      const data = [];
      for (let i = 0; i < this.identifiers.length; i++) {
        let identifier = this.identifiers[i].code;
        let product = (await this.SearchProductUniqueIdentifiers(identifier)).slice(-1)[0];
        if (product) product.query = Object.values(product).join(' ');
        data.push(product);
        if (i == 10) {
          this.loading = false;
          break;
        }
      }
      this.list.data = data;
      console.log(this.list.data)
      this.loading = false;
      */
    },
    methods: {
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      Empty(element) {
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }
      },
      ParcelOrder(order_id) {
        return window.location.origin + '/parcels/orders?id=' + order_id;
      },
      Filter(query) {
        console.log(query)
      },
      async Select(option) {
        await this.Search(option && option.code);
      },
      async Search(identifier) {
        if (!identifier) {
          this.identifier = '';
          this.products = [];
          this.entries = 0;
          return;
        }
        if (identifier == this.identifier) return;
        this.loading = true;
        this.identifier = identifier;
        this.products = (await this.SearchProductUniqueIdentifiers(this.identifier)).reverse()/*.sort((a, b) => b.increment_id.split(':')[1] - a.increment_id.split(':')[1];*/
        //console.log(this.products)
        this.entries = this.products.length;
        this.loading = false;
      },
      async SearchProductUniqueIdentifiers(identifier) {
        return await new Promise((resolve, reject) => {
          BPA.api.SearchProductUniqueIdentifiers(identifier).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let products = response.result || [];
            resolve(products.map(product => ({...product, ...{
              company_name: BPA.company('name', product.res_company_id),
              increment_id: BPA.company('id', product.res_company_id) + ':' + product.increment_id,
              res_company_id: BPA.company('id', product.res_company_id)
            }})));
          }).catch(reject);
        }).catch(e => e);
      },
      async GetProductUniqueIdentifiers() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetProductUniqueIdentifiers().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .grid-wrapper .grid-info .grid-search:after {
    content: none;
  }
  .table.list tbody:not(:empty) {
    border-bottom: 2px solid #eaeaea;
  }
</style>